import React, { useEffect } from "react"
import styled from "styled-components"
import anime from "animejs"
import makeKeyFromTitle from "../../util/Util"
const DEFAULT_DURATION = 20000;
const ComponentContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TickerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 500px;
    overflow-x: hidden;
  `
const TextContainer = styled.div`
  position: absolute;
  white-space: nowrap;
  color: ${props => props.color};
`
const TRANSLATE_DISTANCE = "-500px";
const Ticker = (props)=>{
  const repeatedText = (props.children + " - ").repeat(5);
  const id = makeKeyFromTitle(props.children);
  const animeID = '#'+id;
  useEffect(()=>{
    anime({
      targets: [animeID],
      translateX: TRANSLATE_DISTANCE,
      direction: 'alternate',
      duration: props.duration ? props.duration : DEFAULT_DURATION,
      loop: true,
      autoplay: true,
      easing: 'linear',
    })
  })
  return(
    <ComponentContainer>
      <TickerContainer>
        <TextContainer color={props.color} id={id}>
          {repeatedText}
        </TextContainer>
      </TickerContainer>
    </ComponentContainer>
  )
}
export default Ticker
