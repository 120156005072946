import React, { useEffect } from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import UKFlag from "../icons/uk.svg";
import SpainFlag from "../icons/spain.svg";
import SelectedFlag from "../icons/SelectedFlag.svg";
import anime from "animejs";

const LanguageSelectorContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 20px;
  left: 20px; 
  height: 40px;
  width: 80px;
  border: 2px solid #000000;
  border-radius: 50px;
`
let LinkAndContainer = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    &>{
      height: 100%;
      width: 100%;
    }
`
let SelectedFlagStyled = styled(SelectedFlag)`
  position: absolute
`
const getFlagStyle = (iconLocation, locate)=>{
  const SelectedFlagStyle = {
    height: "16px",
    padding: "7px",
  }
  const UnselectedFlagStyle = {
    height: '30px'
  }
  if(iconLocation===locate) return SelectedFlagStyle;
  return UnselectedFlagStyle
}

const getAnimationStyle = (iconLocation, locate)=>{
  const SelectedAnimationStyle = {}
  const UnselectedAnimationStyle = {
    display: 'none',
  }
  if(iconLocation===locate) return SelectedAnimationStyle;
  return UnselectedAnimationStyle
}

const LanguageSelector = (props)=>{
  useEffect(()=>{
    anime({
      targets: ['.selectedFlagAnimation'],
      rotate: '5turn',
      duration: 12500,
      direction: 'alternate',
      autoplay: true,
      loop: true,
      easing: 'linear'
    })
  })
  return(
    <LanguageSelectorContainer>
      <LinkAndContainer to={"/"}>
        <SelectedFlagStyled  className={"selectedFlagAnimation"} style={getAnimationStyle('en-US', props.locale)}/>
        <UKFlag style={getFlagStyle('en-US', props.locale)}/>
      </LinkAndContainer>
      <LinkAndContainer to={"/es/"} >
        <SelectedFlagStyled className={"selectedFlagAnimation"} style={getAnimationStyle('es', props.locale)}/>
        <SpainFlag style={getFlagStyle('es', props.locale)}/>
      </LinkAndContainer>
    </LanguageSelectorContainer>
  )
}
export default LanguageSelector