export default class Polygon {
  constructor(n, r, x, y, angle){
    this.n = n;
    this.r = r;
    this.x = x;
    this.y = y;
    this.coordinates = this.getCoordinates();
    this.path = this.getPath();
    this.angle = angle;
  }

  getCoordinates(){

    let r = this.r;

    let coordinates = [];

    let basicAngle = (2*Math.PI)/this.n;
    for(let i=0; i<this.n ; i++){
      let angle = (basicAngle * i) + this.angle;
      let yCoord = r * Math.sin(angle);
      let xCoord = r * Math.sin(angle+(Math.PI/2));

      coordinates.push({
        x: (xCoord + this.x).toFixed(0),
        y: (yCoord + this.y).toFixed(0)
      });
    }
    return coordinates;
  };

  getPath(){
    this.coordinates = this.getCoordinates();
    let path = '';
    let j = 0;
    for (let coordinate of this.coordinates){
      if (j === 0){
        path += 'M';
      }
      else{
        path+= 'L';
      }
      path += coordinate.x + ',' + coordinate.y + ' ';
      j++;
    }
    path += 'Z'
    return path;
  }

  rotate(angle){
    this.angle += angle;
    this.coordinates = this.getCoordinates();
  }
}