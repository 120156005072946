import React from "react";
import styled from "styled-components";
const BubbleFullScreenContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 26px;
  height: 100vh;
  width: calc(100% - 52px);
  top: 0;
  left: 0;
  color: white;
  z-index: ${props => props.zIndex};
`
const CloseButton = styled.input`
  top: 0;
  justify-content: flex-end;
  background: none;
  border: none;
  color: white;
  font-size: 50px;
  &:focus{
    outline: none;
  }
  text-align: right;
`
const Icon = styled.img`
  width: 25vw;
  height: 25vw;
  margin: 50px 0;
`
const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
`
const Content = styled.div`
  font-size: 18px;
  font-weight: 300;
`
const FeaturesContainer = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-top: 50px;
`
const SubFeature = styled.div`
  margin: 15px 0;
  margin-left: ${props => props.marginLeft}px;
`
export default class BubbleFullScreen extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
    this.createSubFeatureComponents = this.createSubFeatureComponents.bind(this);
    this.createIconComponent = this.createIconComponent.bind(this);
  }
  createIconComponent(){
    if (this.props.data.icon == null) return null;
    if(this.props.data.icon != null){
      return (<Icon src={this.props.data.icon.file.url}/>);
    }
  }
  createSubFeatureComponents(){
    if(this.props.data.subFeatures == null) return null;
    let marginLeft = 0;
    let subFeaturesComponents = this.props.data.subFeatures.map((subFeatures)=>{
      marginLeft += 30;
      let key = (subFeatures).replace(/\W/g, '').substring(0, 10);
      return(
        <SubFeature key={key} marginLeft={marginLeft}>{subFeatures}</SubFeature>
      )});
    return subFeaturesComponents;
  }
  render(){
    let newZIndex = -1;
    if(this.props.getIsOpen()){
      newZIndex = 1;
    }
    return(
      <BubbleFullScreenContainer zIndex={newZIndex} className={this.props.className}>
        <CloseButton
          type="button"
          value={"✕"}
          onClick={()=>{this.props.changeStateOpen()}}
        />
        {this.createIconComponent()}
        <Title>
          {this.props.data.title}
        </Title>
        <Content>
          {this.props.data.content}
        </Content>
        <FeaturesContainer>
          {this.createSubFeatureComponents()}
        </FeaturesContainer>
      </BubbleFullScreenContainer>
    )
  }
}


