import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
const SEO = (props) => {
  const data = useStaticQuery(graphql`
    query SEOquery{
      site {
        siteMetadata {
          description
          siteUrl
          title
          es {
            title
            description
          } 
        }
      } 
    }
  `);
  const seoData = data.site.siteMetadata;
  if(props.language === 'es'){
    return (
      <Helmet title={seoData.es.title}>
        <meta name="description" content={seoData.es.description}/>
        <meta property="og:description" content={seoData.es.description}/>
        <link rel="alternate" hrefLang="en" href="https://www.ecomm3.com/"/>
        <link rel="alternate" hrefLang="x-default" href="https://www.ecomm3.com/"/>
        <link rel="alternate" hrefLang="es" href="https://www.ecomm3.com/es/"/>
      </Helmet>
    )
  }
  return (
    <Helmet title={seoData.title}>
      <meta name="description" content={seoData.description}/>
      <meta property="og:description" content={seoData.description}/>
      <link rel="alternate" hrefLang="en" href="https://www.ecomm3.com/"/>
      <link rel="alternate" hrefLang="x-default" href="https://www.ecomm3.com/"/>
      <link rel="alternate" hrefLang="es" href="https://www.ecomm3.com/es/"/>
    </Helmet>
  )
}
export default SEO
