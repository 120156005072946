import React from "react";
import styled from "styled-components";
import anime from "animejs";
import BubbleFullScreen from "./BubbleFullScreen";
const BubbleButtonScreenContainer = styled.div`
  position: absolute;
  top: ${props => props.y};
  left: ${props => props.x};
  width: 20px;
  height: 20px;
  opacity: 1;
`
function createAnimationOpen(target){
  return(
    anime({
      targets: target,
      easing:'easeOutQuad',
      loop: false,
      scale: 200,
      autoplay: false,
      opacity: 1,
      duration: 2000
    })
  )
};
function createAnimationFlash(target){
  return(
    anime({
      targets: target,
      easing:'easeOutQuad',
      loop: true,
      autoplay: true,
      keyframes:[
        {
          scale:2,
          opacity: 0,
          duration: 250,
        },
        {
          scale:1,
          opacity: 0.3,
          duration: 2500,
        }
      ],
    })
  )

}
export default class BubbleButtonScreen extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      x: props.x,
      y: props.y,
      r: 10,
      animationFlash: null,
      animationOpen: null,
      isOpen: false,
    }
    this.changeStateOpen = this.changeStateOpen.bind(this);
    this.getIsOpen = this.getIsOpen.bind(this);
  }
  changeStateOpen(){
    this.setState(
      {isOpen: !this.state.isOpen}, ()=>{
        if(this.state.isOpen){
          this.state.animationOpen.play();
          this.state.animationFlash.pause();
        }
        else{
          this.state.animationFlash.play();
        }
      }
    )
  }
  componentDidMount() {
    let animationTarget  = '.' + this.props.className;
    this.setState({animationFlash: createAnimationFlash(animationTarget)});
    this.setState({animationOpen: createAnimationOpen(animationTarget)});
  }
  getIsOpen(){
    return this.state.isOpen;
  }
  render() {
    return(
      <BubbleButtonScreenContainer
        y={this.props.data.yCoordinate}
        x={this.props.data.xCoordinate}
      >
        <svg
          className={this.props.className}
          onClick={this.changeStateOpen.bind(this)}
          width={this.state.r*2}
          height={this.state.r*2}
        >
          <circle cx={this.state.r} cy={this.state.r} r={this.state.r}  fill="#000000" />
        </svg>
        <BubbleFullScreen
          getIsOpen={this.getIsOpen.bind(this)}
          changeStateOpen={this.changeStateOpen.bind(this)}
          data={this.props.data}
        />
      </BubbleButtonScreenContainer>
    );
  }
}
