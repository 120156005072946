import React from "react"
import styled from "styled-components"
import anime from "animejs";
import BubbleButtonScreen from "./BubbleButtonScreen"
import Img from "gatsby-image"

let PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0px;
`
const ImgContainer = styled.div`
  position: relative;
  width: 360px;
`
export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: null,
    }
    this.getBubbleButtons = this.getBubbleButtons.bind(this);
  }
  componentDidMount() {
    this.setState({
      animation: anime({
        targets: '.phoneSVG',
        easing:'easeOutQuad',
        loop: false,
        autoplay: true,
      })
    });
  }
  getBubbleButtons(){
    let bubbleButtonsData = this.props.data.allContentfulFeature.nodes;
    let idCount = 0;
    let bubbleReactElements = bubbleButtonsData.map((node)=>{
      idCount++;
      let id = 'Bubble'+idCount;
      return(
        <BubbleButtonScreen
          key={id}
          className={id}
          data={node}
        />)
      //return(<div>{node.title + id}</div>)
    });
    return bubbleReactElements;
  }
  render(){
    return (
      <PhoneContainer>
        <ImgContainer>
          <Img fixed={this.props.data.fixed.childImageSharp.fixed} className={"phoneSVG"} alt={'eCommerce Store'}/>
          {this.getBubbleButtons()}
        </ImgContainer>
      </PhoneContainer>
    );
  }
}

